<template>
    <main>
        <div class="card  card-waves mt-2 ">
            <div class="card-header ">
               <div class="row">
                    <div class="col-6">
                        <label class="form-label">vacuna</label>
                        <select name="" id="" class="form-control form-control-sm" v-model="vacuna">
                            <option :value="item" v-for="item in vacunas" :key="item.id">{{item.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-6 ">
                        <div class="form-group">
                        <label class="form-label">Fecha</label>
                            <date-picker class="w-100" v-model="rangeDosis" range valueType="format"></date-picker>
                        </div>
                    </div>
               </div>
            </div>
            <div class="card-body text-center">
                <div class="chart-container" v-if="Object.keys(resultadosDosis).length">
                    <div class="chartjs-size-monitor">                                
                        <div class="chartjs-size-monitor-shrink">
                            <canvas id="graficoDosisVacuna" style="display: block; width: 400px; height: 300px;" width="400" height="300" class="chartjs-render-monitor"></canvas>
                        </div>
                    </div>
                </div>
                <div class="align-center " v-else >
                    <img class="mb-0" src="@/assets/img-generales/error.png" width="256">
                    <p ><strong>Sin datos para mostrar...</strong></p>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import registroPaiService from "../../services/regitroPaiService";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Chart from 'chart.js/auto';
import {isEmpty} from "lodash";
import Swal from "sweetalert2";

export default {
    props: ['vacunas'],
    components:{
        DatePicker
    },
    data() {
        return {
            rangeDosis:'',
            graficoDosis:'',
            resultadosDosis:[],
            vacuna:''
        }
    },
    methods: {
        async cargarGraficoDosis(){
            const response = await registroPaiService.graficoVacunasDosis({
                inicio : this.rangeDosis[0],
                fin : this.rangeDosis[1],
                id_vacuna:this.vacuna.id
            });
            this.resultadosDosis= response.data;
            this.$nextTick(() => this.graficoVacunasDosis());
        },
        graficoVacunasDosis(){
            if(this.graficoDosis!==''){
                this.graficoDosis.destroy();
            }
            var grafico_barra_dosis = document.getElementById('graficoDosisVacuna').getContext("2d");


            let label=[];
            let cantidades=[];
            

            this.resultadosDosis.forEach((x,y) => {
                label[y]=x.dosis.nombre_dosis;
                cantidades[y]=x.cantidad;
            });

            
            
            this.graficoDosis= new Chart(grafico_barra_dosis, {
                type: 'pie',
                data:  {
                    labels:label,
                    datasets: [{
                        label: 'Total dosis aplicadas de: '+ this.vacuna.nombre,
                        data:cantidades,
                        fill: false,
                        backgroundColor: [
                        '#0061F2',
                        '#6900C7',
                        '#FEC542',
                        '#00BA94',
                        '#00d2ff'
                        ],
                        borderWidth: 1,
                        hoverBorderWidth: 0,
                        pointBackgroundColor: 'rgb(26, 0, 0)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(26, 168, 0)',
                        
                    }],                    
                },
                options: {
                    
                    elements: {
                        line: {
                            borderWidth: 2
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                // This more specific font property overrides the global property
                                font: {
                                    size: 15
                                }
                            },
                        }
                    },
                }
            });
        },
    },
    watch:{
        rangeDosis: {
            handler: function () {
                if(!isEmpty(this.vacuna)){
                    this.cargarGraficoDosis(this.rangeDosis);
                }else{
                    Swal.fire('Ups!', 'Debe seleccionar una vacuna', 'warning');
                    this.rangeDosis='';
                }
            },
            deep: true
        }
    },
}
</script>