<template>
    <main>
        <div class="card text-center mt-2 card-header-actions scroll" >
            <div class="card-header ">
                Reporte
                <div class="col-8  mt-3">
                    <div class="form-group">
                        <date-picker class="w-100" v-model="range" range valueType="format"></date-picker>
                    </div>
                </div>
            </div>
            <div class="card-body" >
                <div class="chart-container" v-if="Object.keys(resultados).length">
                    <div class="chartjs-size-monitor">                                
                        <div class="chartjs-size-monitor-shrink">
                            <canvas id="grafico" style="display: block; width: 724px; height: 482px;" width="724" height="482" class="chartjs-render-monitor"></canvas>
                        </div>
                    </div>
                </div>
                <div class="align-center " v-else >
                    <img class="mb-0" src="@/assets/img-generales/error.png" width="256">
                    <p ><strong>Sin datos para mostrar...</strong></p>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import registroPaiService from "../../services/regitroPaiService";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Chart from 'chart.js/auto';

export default {
    props: ['vacunas'],
    components:{
        DatePicker
    },
    data() {
        return {
            range:'',
            grafico:'',
            resultados:[],
        }
    },
    methods: {
        async cargarGrafico(){
            const response = await registroPaiService.graficoVacunas({
                inicio : this.range[0],
                fin : this.range[1],
            });
            this.resultados= response.data;
            this.$nextTick(() => this.graficoVacunas());
        },
        
        graficoVacunas(){
            if(this.grafico!==''){
                this.grafico.destroy();
            }
            var grafico_barra = document.getElementById('grafico').getContext("2d");


            let label=[];
            let cantidades=[];
            this.resultados.forEach((x,y) => {
                label[y]=x.vacuna.abbr_name;
                cantidades[y]=x.cantidad;
            });
            
            this.grafico= new Chart(grafico_barra, {
                type: 'bar',
                data:  {
                    labels:label,
                    datasets: [{
                        label: 'Total de Vacunas aplicadas',
                        data:cantidades,
                        fill: false,
                        backgroundColor: [
                        '#0061F2',
                        '#6900C7',
                        '#FEC542',
                        '#00BA94',
                        '#00d2ff'
                        ],
                        borderWidth: 1,
                        hoverBorderWidth: 0,
                        pointBackgroundColor: 'rgb(26, 168, 0)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(26, 168, 0)',
                        
                    }],                    
                },
                options: {
                    indexAxis: 'y',
                    elements: {
                        line: {
                            borderWidth: 1
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                // This more specific font property overrides the global property
                                font: {
                                    size: 15
                                }
                            },
                        }
                    },
                }
            });
        },
    },
    watch:{
        range: {
            handler: function () {
                this.cargarGrafico(this.range);
            },
            deep: true
        },
    },
}
</script>
<style scoped>
    .scroll {
    max-height: 400px;
    overflow-y: auto;
}
</style>