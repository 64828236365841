<template>
    <main>
        <div class="card text-center mt-2 card-header-actions " >
            <div class="card-header ">
                Reporte
                <div class="col-8  mt-3">
                    <div class="form-group">
                        <date-picker class="w-100" v-model="rangeDay" range valueType="format"></date-picker>
                    </div>
                </div>
            </div>
            <div class="card-body" >
                <div class="chart-container" v-if="Object.keys(resultadosDay).length">
                    <div class="chartjs-size-monitor">                                
                        <div class="chartjs-size-monitor-shrink">
                            <canvas id="graficoDay" style="display: block; width: 724px; height: 352px;" width="724" height="352" class="chartjs-render-monitor"></canvas>
                        </div>
                    </div>
                </div>
                <div class="align-center " v-else >
                    <img class="mb-0" src="@/assets/img-generales/error.png" width="256">
                    <p ><strong>Sin datos para mostrar...</strong></p>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import registroPaiService from "../../services/regitroPaiService";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Chart from 'chart.js/auto';

export default {
    components:{
        DatePicker
    },
    data() {
        return {
            rangeDay:'',
            graficoDay:'',
            resultadosDay:[],
        }
    },
    methods: {
        async cargarGraficoDay(){
            const response = await registroPaiService.graficoVacunasDay({
                inicio : this.rangeDay[0],
                fin : this.rangeDay[1],
            });
            this.resultadosDay= response.data;
            this.$nextTick(() => this.graficoVacunasByDay());
        },
        
        graficoVacunasByDay(){
            if(this.graficoDay!==''){
                this.graficoDay.destroy();
            }
            var grafico_barra_day = document.getElementById('graficoDay').getContext("2d");


            let label=[];
            let cantidades=[];
            this.resultadosDay.forEach((x,y) => {
                label[y]=x.fecha;
                cantidades[y]=x.cantidad;
            });
            
            this.graficoDay= new Chart(grafico_barra_day, {
                type: 'line',
                data:  {
                    labels:label,
                    datasets: [{
                        label: 'Total de Vacunas aplicadas por dia',
                        data:cantidades,
                        fill: false,
                        borderColor: 'rgb(75, 192, 192)',
                        backgroundColor: [
                        '#0061F2',
                        '#6900C7',
                        '#FEC542',
                        '#00BA94',
                        '#00d2ff'
                        ],
                        borderWidth: 2,
                        hoverBorderWidth: 0,
                        tension: 0.1,
                        pointBackgroundColor: 'rgb(26, 168, 0)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(26, 168, 0)',
                        
                    }],                    
                },
                options: {
                   
                    elements: {
                        line: {
                            borderWidth: 1
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                // This more specific font property overrides the global property
                                font: {
                                    size: 15
                                }
                            },
                        }
                    },
                }
            });
        },
    },
    watch:{
        rangeDay: {
            handler: function () {
                this.cargarGraficoDay(this.rangeDay);
            },
            deep: true
        },
    },
}
</script>
<style scoped>
    .scroll {
    max-height: 400px;
    overflow-y: auto;
}
</style>